import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import TripleColumnBlock from "@components/tripleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"
import ReactPlayer from 'react-player'

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Midtown Atlanta Web Design - Woodruff Arts - Websuasion"
        ogDescription="Are you looking for a professional web designer serving Midtown Atlanta? See how we enabled The Woodruff Arts Center to achieve their goals."
        image="/images/case-studies/midtown-atlanta-web-design-woodruff-arts.jpg"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Midtown Atlanta Web Design</h1>
						<h3>A Case Study of The Woodruff Arts Center</h3>
            <p>
							<span className="websuasion">websuasion</span> worked with our friends at Twleve Foot Guru to provide The Woodruff Arts Center with the specific website design they needed to achieve their goals.
            </p>
						<p>
							Are you looking for a professional web designer serving the Midtown Atlanta area to help transform your business? Let's schedule an introductory call.
						</p>
						<FormWrapper
		          modal
		          buttonProps={{
		            content: "Schedule An Introductory Call",
		            size: "tiny",
		            icon: "calendar alternate outline",
		          }}
		        >
		          <RequestMoreInfo />
		        </FormWrapper>
          </div>
        }
        textRight={
          <div>
					<img src="/images/case-studies/midtown-atlanta-web-design-woodruff-arts.jpg" className="rounded-image" alt="Midtown Atlanta Web Design - Websuasion" />
          </div>
        }
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<p>
								<img src="/images/case-studies/woodruff-arts-center-logo.webp" alt="Midtown Atlanta Web Design - Websuasion" />
							</p>
			        <p>
								The Woodruff Arts Center is one of the largest arts centers in the world, home to the Tony Award-winning Alliance Theatre, the Grammy Award-winning Atlanta Symphony Orchestra and the High Museum of Art, the leading art museum in the Southeast. Each year, these centers of artistic excellence play host to 1 million patrons at The Woodruff Arts Center’s Midtown Atlanta location, one of the only arts centers in the U.S. to host both visual and performing arts on a single campus. The Woodruff Arts Center also offers remarkable education programming through each of its arts partners. Through their combined efforts, The Woodruff Arts Center serves 1 million patrons and more than 200,000 students annually, making it the largest arts educator in Georgia.
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="violet">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Client Testimonial</h2>
	            <Blockquote>For the Woodruff Arts Center project, Websuasion created both design, SASS styles, and html for the website. Their attention to detail allowed us to finish up the code and implementation of the site quickly and efficiently.</Blockquote>
							<p>
								- John Clark, President, Twelve Foot Guru
							</p>
	          </div>
	        }
	        textRight={
	          <div>
							<img src="/images/case-studies/12ftguru-john-clark.jpg" className="rounded-square" alt="Midtown Atlanta Web Design - Websuasion" width="300" />
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
      <TextBlock
        textPadded
        textLeft={
          <div>
						<h2>Client Needs</h2>
						<ul>
							<li>Redesigned website</li>
							<li>Ability to quickly update content, photos, and videos</li>
						</ul>
          </div>
        }
        textRight={
          <div>
						<h2>websuasion Solutions</h2>
						<ul>
							<li>Clean, modern design with desktop, tablet, and mobile responsive layout</li>
							<li>Custom wordpress theme allowing for quick updates by client</li>
						</ul>
          </div>
        }
      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Marketing Website Design</h2>
							<p>
								We were approached to create a web design for the Woodruff Arts Center. Our partners at Twelve Foot Guru were handling customization of Wordpress to take care of the Woodruff Arts Center's specific content development needs. We were subcontracted to create a device-responsive custom wordpress theme with reusable modular components to be used throughout the site. The design also included custom navigation, an events calendar, a news section, and social media integration.
							</p>
							<p>
		            <ReactPlayer
			            url= '/files/case-studies/woodruffartscenter.mp4'
			            width='100%'
			            height='100%'
			            controls = {true}
		            />
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage
